<template>
  <div class="table-responsive h-100">
    <b-table
      :fields="tableFields"
      :items="searchAlunos"
      bordered
      hover
      class="text-center"
      id="tableAlunos"
      :per-page="perPage"
      :current-page="currentPage"
    >

      <!-- Data de Nascimento -->
      <template v-slot:cell(dtNascimento)="aluno">
        {{new Date(aluno.item.dtNascimento).toLocaleDateString() || 'Não informado'}}
      </template>

      <!-- Opções -->
      <template v-slot:cell(opcoes)="aluno">
        <b-dropdown
          size="sm"
          :right="true"
          variant="primary-dark"
        >
          <template slot="button-content">
            <font-awesome-icon :icon="['fas', 'cog']" />
          </template>

          <b-dropdown-item @click="gotoEditAluno(aluno.item)">Editar</b-dropdown-item>

          <b-dropdown-item @click="deleteAluno(aluno.item._id, aluno.item.nome)">Excluir</b-dropdown-item>

          <b-dropdown-item
            v-b-modal.modal-pagamento
            @click.prevent="$emit('alunoSelecionado', aluno.item)"
          >Informar Pagamento</b-dropdown-item>

          <b-dropdown-item @click="informarDesistencia(aluno.item._id)">Informar Desistência</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-alert
      variant="secondary"
      :show="alunos.loading"
      class="text-center m-2"
    >
      <font-awesome-icon
        :icon="['fas', 'spinner']"
        spin
      />
    </b-alert>

    <b-alert
      :show="searchAlunos.length == 0 && !alunos.loading"
      variant="secondary"
      class="text-center m-2"
    >Nenhum aluno foi encontrado</b-alert>

    <div class="d-flex justify-content-center align-items-center mt-3">
      <b-pagination
        v-model="currentPage"
        :total-rows="searchAlunos.length"
        :per-page="perPage"
        aria-controls="tableAlunos"
        align="fill"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as AlunoService from "@/services/AlunoService";

const defaultCellValue = item => (item ? item : "Não informado");

export default {
  props: ["searchField", "alunoSelecionado"],
  data: () => ({
    perPage: 10,
    currentPage: 1,
    tableFields: [
      { key: "matricula", label: "Matrícula" },
      { key: "nome", label: "Nome", sortable: true },
      { key: "cpf", label: "CPF", formatter: defaultCellValue },
      { key: "rg", label: "RG", formatter: defaultCellValue },
      { key: "dtNascimento", label: "Nascimento", formatter: defaultCellValue },
      { key: "opcoes", label: "Opções" }
    ]
  }),
  computed: {
    ...mapState(["alunos"]),
    searchAlunos() {
      return this.alunos.items.filter(aluno => {
        let searchString = this.searchField.trim().toLowerCase();
        const nomeAluno = aluno.nome.toLowerCase();

        if (aluno.cpf) {
          const numerosCPF = aluno.cpf
            .split(".")
            .join("")
            .replace("-", "");
          searchString = searchString.split(".").join("");

          return (
            nomeAluno.includes(searchString) ||
            numerosCPF.includes(searchString)
          );
        }

        return nomeAluno.includes(searchString);
      });
    }
  },
  methods: {
    deleteAluno(id, nome) {
      this.$swal({
        title: "Remover Aluno",
        text: `Deseja realmente remover o aluno ${nome}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim"
      }).then(({ value: remover }) => {
        if (remover) {
          this.$store.dispatch("alunos/remove", { id });
        }
      });
    },
    gotoEditAluno(aluno) {
      this.$store.commit("alunos/beginEdit", aluno);
      this.$router.push("/alunos/cadastro/" + aluno._id);
    },
    informarDesistencia(id) {
      this.$swal({
        title: "Informar Desistência",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        html: this.getHtml(),
        preConfirm: this.getPreConfirm(),
        onOpen: this.getOnOpen()
      }).then(({ value }) => {
        if (value) {
          AlunoService.informarDesistencia(id, value.data.toJSON());
        }
      });
    },
    getHtml() {
      return (
        '<div class="d-flex align-items-center">' +
        '<input id="swal-input-dia" placeholder="DD" class="swal2-input swal2-placeholder text-center">&nbsp;/&nbsp;' +
        '<input id="swal-input-mes" placeholder="MM" class="swal2-input swal2-placeholder text-center">&nbsp;/&nbsp;' +
        '<input id="swal-input-ano" placeholder="YYYY" class="swal2-input swal2-placeholder text-center">' +
        "</div>"
      );
    },
    getPreConfirm() {
      const fn = () => {
        return new Promise(function(resolve) {
          const dia = document.querySelector("#swal-input-dia").value;
          const mes =
            parseInt(document.querySelector("#swal-input-mes").value) - 1;
          const ano = document.querySelector("#swal-input-ano").value;

          resolve({
            data: new Date(ano, mes, dia)
          });
        });
      };

      return fn;
    },
    getOnOpen() {
      const fn = () => {
        const data = new Date();
        document.querySelector("#swal-input-dia").value = this.forceTwo(
          data.getDate()
        );
        document.querySelector("#swal-input-mes").value = this.forceTwo(
          data.getMonth() + 1
        );
        document.querySelector("#swal-input-ano").value = data.getFullYear();
      };

      return fn;
    },
    forceTwo(n) {
      return n.toString().padStart(2, "0");
    }
  }
};
</script>

<style scoped>
.table,
.table tr,
.table td {
  background-color: #fafafa !important;
}
</style>




<style lang="scss">
.pagination > li > a {
  background-color: white;
  color: #5a4181;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
  outline-color: $primary-dark !important;
  box-shadow: none;
}

.pagination > .active > a {
  color: white;
  background-color: $primary-dark !important;
  border: solid 1px $primary-dark !important;
}

.pagination > .active > a:hover {
  filter: brightness(0.7);
  border: solid 1px #5a4181 !important;
}
</style>