import api from "./";

export function fetchAll() {
  return api.get("/caixa");
}

export function add(dadosCaixa) {
  return api.post("/caixa", dadosCaixa);
}

export function addMoney(id, valor, motivo) {
  return api.put(`/caixa/${id}/adicionarDinheiro`, { valor, motivo });
}

export function removeMoney(id, valor, motivo) {
  return api.put(`/caixa/${id}/retirarDinheiro`, { valor, motivo });
}

export function remove(idCaixa) {
  return api.delete(`/caixa/${idCaixa}`);
}

export function toggleMostrarNaDashboard(id) {
  return api.put(`/caixa/${id}/toggleMostrarNaDashboard`);
}
export function toggleInvestimento(id) {
  return api.put(`/caixa/${id}/toggleInvestimento`);
}
