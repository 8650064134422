<template>
  <div id="graph-despesas">
    <apexchart
      width="800"
      :options="chartOptions"
      :series="chartSeries"
    ></apexchart>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import * as DespesaService from "@/services/DespesaService";
import { mapState } from "vuex";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    ...mapState(["caixas"]),
  },
  data() {
    return {
      chartOptions: {
        labels: [],
        chart: {
          type: "pie",
        },
        title: {
          text: "Para onde está indo o seu dinheiro?",
          style: { fontSize: "25px", fontFamily: "", fontWeight: "bold" },
          margin: 10,
        },
        legend: {
          fontSize: "15px",
          floating: false,
        },
      },
      chartSeries: [],
    };
  },
  async created() {
    await this.generateFinancialData();
  },
  methods: {
    async generateFinancialData() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const monthString = currentMonth.toString().padStart(2, "0");
      await DespesaService.fetch(currentYear, monthString).then((res) => {
        res.data.despesas.forEach((despesa) => {
          const nomeDespesa = despesa.nome;
          const valorDespesa = despesa.valor;
          this.chartOptions.labels.push(nomeDespesa);
          this.chartSeries.push(valorDespesa);
        });
      });
      this.caixas.caixas.forEach((x) => {
        if (x.investimento) {
          this.chartOptions.labels.push(x.nome);
          this.chartSeries.push(x.valor);
        }
      });
    },
  },
};
</script>
<style scoped>
</style>