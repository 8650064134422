<template>
  <b-col>
    <b-card bg-variant="light">
      <b-row>
        <b-col md="6">
          <h3>Curso</h3>
          <h6 v-if="isEditing">Matrícula: {{dados.matricula}}</h6>
          <hr />

          <b-row>
            <b-col md="6">
              <b-form-group label="* Data de Início:">
                <date-picker
                  format="DD/MM/YYYY"
                  valueType="date"
                  placeholder="dd/mm/aaaa"
                  v-model="dados.dtInicio"
                  class="w-100"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="* Data de Fim:">
                <date-picker
                  format="DD/MM/YYYY"
                  valueType="date"
                  placeholder="dd/mm/aaaa"
                  v-model="dados.dtFim"
                  class="w-100"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group label="Nível:">
                <b-form-input
                  placeholder="e.g Avançado"
                  v-model="dados.nivel"
                  maxlength="150"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <b-form-group label="Caixa:">
                <b-form-select v-model="dados.caixa">
                  <option :value="null" disabled>Selecione o Caixa</option>
                  <option
                    v-for="caixa in this.caixas.caixas"
                    :value="caixa.id"
                    :key="caixa.id"
                  >
                    {{ caixa.nome }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Valor:">
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">R$</span>
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="0000,00"
                    v-model="dados.valor"
                    type="number"
                    step="100"
                    min="0"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Dia Pagamento:">
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">Dia</span>
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="e.g 15"
                    v-model="dados.diaPagamento"
                    type="number"
                    min="0"
                    max="31"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="6">
          <h3>Conta do Aluno</h3>

          <hr />

          <b-col md="12">
            <b-form-group label="* Usuário">
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">
                    <font-awesome-icon :icon="['fas', 'user']" />
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  placeholder="Digite o usuário do aluno"
                  v-model="dados.auth.username"
                  :disabled="isEditing"
                  :state="dados.auth.username.length > 3"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="* Senha">
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">
                    <font-awesome-icon :icon="['fas', 'key']" />
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  placeholder="Digite a senha do aluno"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="dados.auth.password"
                  :disabled="isEditing"
                  :state="dados.auth.password.length > 3"
                />
              </b-input-group>
            </b-form-group>

            <font-awesome-icon
              :icon="['fas', 'user-plus']"
              style="cursor: pointer"
              @click="generateAuth()"
              v-if="!isEditing"
            />

            <font-awesome-icon
              :icon="['fas', !showPassword ? 'eye' : 'eye-slash']"
              class="see-pass"
              @click="showPassword = !showPassword"
            />
          </b-col>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
import DatePicker from "vue2-datepicker";

import { mapState } from "vuex";

export default {
  props: ["dados", "validFields", "isEditing"],
  data: () => ({
    showPassword: true,
  }),
  components: {
    DatePicker,
  },
  computed: {
    ...mapState(["caixas"]),
  },
  methods: {
    generateAuth() {
      this.$emit("generateAuth");
    },
  },
};
</script>

<style scoped>
.see-pass {
  float: right;
  cursor: pointer;
}
</style>
